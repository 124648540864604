import axios from './axios-config';
import { objectKeysToLowerCase } from './utilCommon';

const currentVersion = "/v1"

export const Urls = {
  TokenAndRosterPermission: "/token",
  verifyInfo: '/verify-info',
  CommitteeSummaryByCommitteeId: "/roster/committee/summary/:committeeId",
  MemberAutoSearch: "/roster/member/search/:committeeId/:searchType/:searchString",
  MemberDetails: "/roster/member/detail/:memberId",
  UpdateNotes: "/roster/member/:memberId/notes",
  UpdateClass: "/roster/member/:memberId/classification",
  UpdateVote: "/roster/member/:memberId/vote",
  DownloadReport: "/committee/reports",
  clearRedis: "/logout",
  GetCommittInfo: "/roster/committee/committeeInfo/:committeeId",
  GetRosterReportAndVote: "/master/rosterreportandvote/:committeeId",
  flagFeature: "/master/featureflag",
  CheckFileExist: "/committee/checkFileExist"
};

const addQueryParameters = (url, params) => {
  const queryList = [];
  Object.keys(params).forEach(k => {
    queryList.push(`${k}=${encodeURIComponent(params[k])}`);
  });

  const prefixCharacter = url.indexOf("?") === -1 ? "?" : "&";

  return `${url + prefixCharacter + queryList.join("&")}`;
};

const replaceParams = (url, params) => {
  Object.keys(params).forEach(k => {
    url = url.replace(`:${k}`, encodeURIComponent(params[k]));
  });
  return url;
};

export const buildURL = (url, queryParam, paramReplacements) => {
  let API_URL = process.env.REACT_APP_API_URL;
  if (url === Urls.flagFeature) {
    API_URL = process.env.REACT_APP_OPEN_API_URL;
  }
  if (queryParam) {
    url = addQueryParameters(url, queryParam);
  }

  if (paramReplacements) {
    url = replaceParams(url, paramReplacements);
  }

  return API_URL + currentVersion + url;
};

export const callAPI = (url, method, postData, isHeader, isResponseInLowerCase, cb) => {
  let axiosObj = {
    baseURL: '',
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
      'Access-Control-Allow-Origin': '*',
    }
  }
  method = method || "get";
  axios[method](url, postData, axiosObj).then(
    res => {
      let responseData = res.data;
      if (isResponseInLowerCase) {
        responseData = objectKeysToLowerCase(responseData);
      }
      if (responseData && responseData.responsestatus == false) {
        console.log("error =>", responseData);
      }
      cb && cb(responseData);
    },
    err => {
      console.log("error =>", err);
      cb && cb(err);
    }
  ).catch(error => {
    console.log("catch =>", error);
    cb && cb(null);
  });
};
