import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Alert } from 'react-bootstrap';
import { hideMessage } from '../../redux/shared/actions';

class ToastMessage extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      type: this.props.content && this.props.content.type ? this.props.content.type : 'danger',
      message: this.props.content && this.props.content.text ? this.props.content.text : 'Unknown error occured.',
      uuidNumber: this.props.uuidNumber
    };
  }

  componentDidMount() {
    let timeout = 5000;

    if (this.state.type === 'danger') {
      timeout = 20000;
    }

    setTimeout(() => {
      this.props.handleDismiss();
    }, timeout)
  }

  render() {
    const { type, message, uuidNumber } = this.state;
    const { testId, handleDismiss } = this.props;

    return (
      <Alert data-testid={testId} onClose={handleDismiss} variant={type} className={'astm-alert bg-' + type + '--light'} dismissible>
        <div className='flex-box'>
          <span data-testid={testId ? testId : 'toast-message'} className="astm-alert-text">{message}</span>
          { type === 'danger' && uuidNumber !== null && <span data-testid={testId ? testId : 'toast-message'} className="astm-alert-text text-end">Record ID: {uuidNumber}</span> }
        </div>
      </Alert>

    )
  }
}

const mapDispatchToProps = (dispatch) => ({
  handleDismiss: () => { hideMessage(dispatch); }
});

export default connect(
  null,
  mapDispatchToProps
)(ToastMessage);
