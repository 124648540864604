/* eslint-disable no-useless-escape */
// responsive setting
export const maxWidthValue = {
    tabletOrMobile: 1024,
    mobile: 767
}

export const minWidthValue = {
    tabletOrDesktop: 768,
    desktop: 1025
}

// inactivity report url
export const inActivityURL = process.env.REACT_APP_In_Activity_URL

// classified constant
export const classConsumerName = 'Consumer';
export const classProducerName = 'Producer';
export const classGeneralInterestName = 'General Interest';
export const classUnClassifiedName = 'Unclassified';

// vote constant
export const voteNoPendingCode = 'PD';
export const voteNoRequestNoVoteCode = 'RN';
export const voteNoInactiveCode = 'IA';
export const voteNoRedundantInterestCode = 'RI';
export const voteNoNonVoterCode = 'NV';
export const voteNoChangedEmploymentCode = 'CE';
export const voteYesCode = 'YE';
export const voteNoWaitListCode = 'WL';

export const voteNoPendingId = 1;
export const voteNoRequestNoVoteId = 2;
export const voteNoInactiveId = 3;
export const voteNoRedundantInterestId = 4;
export const voteNoNonVoterId = 5;
export const voteNoChangedEmploymentId = 6;
export const voteYesId = 7;
export const voteNoWaitListId = 8;

export const memberStatusName = {
    active: "active",
    historical: "historical"
}

export const membershipOrderType = {
    member: "member",
    renew: "renew",
    reinstate: "reinstate"
}

export const membershipTypeId = {
    honorary: 1,
    perpetualInstitution: 2,
    organizational: 3,
    affilate: 4,
    memberInstitution: 5,
    participating: 6,
    informational: 7,
    exemptMember: 8,
    seniorMember: 9,
    lifeMember: 10,
    perpetualIndustry: 11,
    temporary: 12,
    isoJoint: 13,
    represntative: 14,
    newMember: 15,
    cooperativeAgreement: 16,
    studentMember: 17,
    mouMember: 18
}

export const historicalMonthCheck = 36;

export const excelColumns = (roasterNotesRequired) => {
    const columnData = [
        { title: "Main Committee/Subcommittee/Section", style: { font: { sz: "10", bold: true, name: "Arial" } } },
        { title: "Officer Title", style: { font: { sz: "10", bold: true, name: "Arial" } } },
        { title: "Name", style: { font: { sz: "10", bold: true, name: "Arial" } } },
        { title: "Organization", style: { font: { sz: "10", bold: true, name: "Arial" } } },
        { title: "Join Date", style: { font: { sz: "10", bold: true, name: "Arial" } } },
        { title: "Classification", style: { font: { sz: "10", bold: true, name: "Arial" } } },
        { title: "Classification Assigned Date", style: { font: { sz: "10", bold: true, name: "Arial" } } },
        { title: "Official Vote", style: { font: { sz: "10", bold: true, name: "Arial" } } },
        { title: "No Vote Reason", style: { font: { sz: "10", bold: true, name: "Arial" } } },
        { title: "Vote Assigned Date", style: { font: { sz: "10", bold: true, name: "Arial" } } },
        { title: "Address", style: { font: { sz: "10", bold: true, name: "Arial" } } },
        { title: "City", style: { font: { sz: "10", bold: true, name: "Arial" } } },
        { title: "State", style: { font: { sz: "10", bold: true, name: "Arial" } } },
        { title: "Postal Code", style: { font: { sz: "10", bold: true, name: "Arial" } } },
        { title: "Country", style: { font: { sz: "10", bold: true, name: "Arial" } } },
        { title: "Phone", style: { font: { sz: "10", bold: true, name: "Arial" } } },
        { title: "Fax", style: { font: { sz: "10", bold: true, name: "Arial" } } },
        { title: "Email", style: { font: { sz: "10", bold: true, name: "Arial" } } },
        { title: "Member Type", style: { font: { sz: "10", bold: true, name: "Arial" } } }
    ];
    if (roasterNotesRequired) {
        columnData.push({ title: "Main Committee Roster Notes", style: { font: { sz: "10", bold: true, name: "Arial" } } })
    }
    return columnData;
}

export const officialVoteOptionsAsPerClassifications = [
    {
        classificationId: 'Consumer',
        classified: [
            'YE',
            'RN',
            'IA',
            'RI',
            'UA'
        ],
        unclassified: [
            'YE',
            'RN',
            'IA',
            'RI',
            'UA'
        ]
    },
    {
        classificationId: 'General Interest',
        classified: [
            'YE',
            'RN',
            'IA',
            'RI',
            'UA'
        ],
        unclassified: [
            'YE',
            'RN',
            'IA',
            'RI',
            'UA'
        ]
    },
    {
        classificationId: 'Producer',
        classified: [
            'YE',
            'RN',
            'WL',
            'IA',
            'RI',
            'UA'
        ],
        unclassified: [
            'YE',
            'RN',
            'IA',
            'RI',
            'UA'
        ]
    },
    {
        classificationId: 'Unclassified',
        classified: [],
        unclassified: [
            'YE',
            'RN',
            'IA',
            'RI',
            'UA'
        ]
    },
    {
        classificationId: 'User',
        classified: [
            'YE',
            'RN',
            'IA',
            'RI',
            'UA'
        ],
        unclassified: [
            'YE',
            'RN',
            'IA',
            'RI',
            'UA'
        ]
    }
];

// Classification information primary activities list
export const activitywiseFieldName = [
    {
        primaryActivityIds: [1, 2, 3, 4],
        fieldName: 'manufacturer'
    },
    {
        primaryActivityIds: [6],
        fieldName: 'governmentagency'
    },
    {
        primaryActivityIds: [7],
        fieldName: 'academia'
    },
    {
        primaryActivityIds: [5, 11],
        fieldName: 'consultant'
    },
    {
        primaryActivityIds: [8],
        fieldName: 'consumer'
    },
    {
        primaryActivityIds: [9],
        fieldName: 'consumeradvocacy'
    },
    {
        primaryActivityIds: [10],
        fieldName: 'other'
    }
];

export const isSearchingServer = false; // false - if you want to search locally, true - if you want to search on server

export const defaultLengthOfVisibleOptions = 2; // By default visible options in multi-select

// Blanck value
export const blankValue = '';

// UI date format
export const uiDateFormat = 'MM/DD/YYYY';

export const numRegex = /^[0-9+\(\)#\.\,\s\/-]+$/

export const emailRegex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i

export const ValidationMessage = {
    adminPasswordIncorrect: "Password is incorrect",
    emailOrIdRequired: "Either Member Account Number or Email is required.",
    ConsultingFirmName: 'Name of your Consulting Firm is required.',
    OrganizationName: 'Organization Name is required.',
    ProductDescription: 'Products/services is required.',
    ConsultingOrgName: 'Name of the Organization is required.',
    ConsultingProductSells: 'Produces/sells is required.',
    ConsultingBusinessActivity: 'Business activities is required.',
    ConsumerAdvocacyName: 'Consumer Advocacy Group is required.',
    AdvocacyEmail: 'Email is required.',
    AdvocacyDescription: 'This field is required.',
    GovAgencyName: 'Government Agency Name is required.',
    GovCommitteeInterest: 'This field is required.',
    AceUniversityName: 'University/College/Institution Name is required.',
    AceInterestNature: 'This field is required.',
    OtherAffilation: 'Affiliation is required.',
    OtherInterestNature: 'This field is required.',
    InvalidEmail: 'Enter valid Email.',
    UniversityName: 'Name of your College/University is required.',
    UniversityAddress1: 'Address Line 1 is required.',
    UniversityCity: ' City is required.',
    UniversityCountry: 'Country is required.',
    UniversityZipCode: 'Zip/Postal Code is required.',
    UniversityState: 'University state is required.',
    GraduationMonth: 'Graduation month is required.',
    GraduationYear: 'Graduation year is required.',
    FirstName: 'First Name is required.',
    LastName: 'Last Name is required.',
    City: 'City is required.',
    ZipCode: 'Zip/Postal Code is required.',
    Phone: 'Phone is required.',
    Email: 'Email is required.',
    Country: 'Country is required.',
    State: 'State is required.',
    Address1: 'Address Line 1 is required.',
    ConsultingService: 'Consulting Service is required.',
    UserName: 'User Name is required.',
    Password: 'Password is required.',
    ConfirmPassword: 'Confirm Password is required.',
    PasswordDoNotMatch: 'Password and Confirm Password should be same.',
    NumbersOnly: 'Enter valid phone number.',
    ConsumerAdvocacyGroup: 'This field is required.',
    ConsultingYourDescription: 'This field is required.',
    VolumeFormat: 'Volume Format is required.',
    OrganizationConsultingRetained: 'This field is required.',
    RequiredField: 'Required Field.'
};

export const checkDoubleQoutes = (str) => {
    if (str.charAt(0) === '"' && str.charAt(str.length - 1) === '"')
        str = str.slice(1, -1);
    return str;
}

export const deepCopy = (obj) => {
    return JSON.parse(JSON.stringify(obj));
}

export const academiaActivityConstants = {
    universityCollegeInstitutionName: 'University/College/Institution Name',
    department: 'Department',
    universityCollegeInstitutionWebsite: 'University/College/Institution Website',
    natureOfInterest: 'What is the nature of your interest in the standards developed by the Committees you seek to join?',
    clickHereToType: 'Click here to type',
    enterUrlHere: 'Enter URL here'
}

export const otherActivityConstants = {
    affiliation: 'What is your affiliation?',
    natureOfInterest: 'What is the nature of your interest in the standards developed by the Committees you seek to join?',
    clickHereToType: 'Click here to type'
}

export const formComponentConstants = {
    content: "Applicants shall clearly document their relevance to the committee or subcommittee based on whether they represent interests that produce, use, regulate, or procure a material, product, system, or service covered by the scope of the committee or subcommittee.",
    placeholder: "Indicate the relevance of your products/services to the committee",
    clickHereToType: 'Click here to type'
}

export const governmentAgencyActivityConstants = {
    websiteAddress: "Website address of agency",
    branchOfficeDivision: "Branch/Office/Division",
    governmentAgencyName: 'Government Agency Name',
    clickHereToType: 'Click here to type',
    natureOfInterest: 'What is the nature of your interest in the standards developed by the Committees you seek to join?'
}
export const nameFormatTypeEnum = {
    'FL': 1,
    'LF': 2,
    'LFM': 3,
    'FML': 4
}
export default {
    classConsumerName,
    classProducerName,
    classGeneralInterestName,
    classUnClassifiedName,
    voteNoPendingId,
    voteNoRequestNoVoteId,
    voteNoInactiveId,
    voteNoRedundantInterestId,
    voteNoNonVoterId,
    voteNoChangedEmploymentId,
    voteYesId,
    voteNoWaitListId,
    excelColumns,
    blankValue,
    uiDateFormat,
    numRegex,
    emailRegex,
    ValidationMessage,
    checkDoubleQoutes,
    deepCopy,
    academiaActivityConstants,
    otherActivityConstants,
    formComponentConstants,
    governmentAgencyActivityConstants,
    nameFormatTypeEnum
}