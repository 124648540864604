import React, { Component } from "react";
import { connect } from "react-redux";
import { Tab, Nav } from 'react-bootstrap'
import { isEqual, orderBy, map } from "lodash";
import MemberList from "./MemberList";
import RosterReports from "./RosterReports";
import {
  setClassFilter,
  setVoteFilter,
  setBasicSearch
} from "../../redux/filterValues/actions";

// reponsive
import MediaQuery from "react-responsive";
import { maxWidthValue, minWidthValue } from "../../helpers/constant";

class RosterAndReportsTabComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      committeeClassificationList: [],
      voteList: [],
      activeTabKey: 'roster'
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { committeeClassificationList, voteList } = nextProps;
    if (
      !(
        isEqual(
          committeeClassificationList,
          prevState.committeeClassificationList
        ) && isEqual(voteList, prevState.voteList)
      )
    ) {
      let sortedClassList = orderBy(
        committeeClassificationList,
        ["name"],
        ["asc"]
      );
      let classFilter = map(sortedClassList, classObj => {
        return {
          key: classObj.committeeclassificationid,
          label: classObj.name,
          value: classObj.committeeclassificationid,
          checked: true
        };
      });
      let sortedVoteList = orderBy(voteList, ["description"], ["asc"]);

      let voteFilter = map(sortedVoteList, voteObj => {
        return {
          key: voteObj.code,
          label: voteObj.description,
          value: voteObj.voteid,
          checked: true
        };
      });
      nextProps.setVoteFilter(voteFilter);
      nextProps.setClassFilter(classFilter);
      nextProps.setBasicSearch("");
      return {
        committeeClassificationList,
        voteList
      };
    }
    return null;
  }

  handleTabChange = (eventKey) => {
    this.setState({ activeTabKey: eventKey });
  }

  render() {
    const { rosterReport, selectedCommitteeWithPermission } = this.props;
    const { activeTabKey } = this.state;
    return (
      <>
        <MediaQuery minWidth={minWidthValue.tabletOrDesktop}>
          {selectedCommitteeWithPermission.updateClassification || selectedCommitteeWithPermission.updateVoting ? null : (
            <div className="permsMsgWrap">
              <span className="permission-message">You do not have the permissions to edit Roster.</span>
            </div>
          )}
          <Tab.Container activeKey={activeTabKey} onSelect={this.handleTabChange} >
            <Nav variant="tabs" as="ul" className="astm-nav-tabs" id="rrTab" role="tablist" data-testid="rosterReportData">
              <Nav.Item as="li">
                <Nav.Link as="a" eventKey="roster" href="javascript:void(0)" id="roster" data-toggle="tab" data-testid="rosterClick" role="tab" aria-controls="roster" aria-selected="true">
                  Roster
                </Nav.Link>
              </Nav.Item>

              {rosterReport && rosterReport.length > 0 &&
                <Nav.Item as="li">
                  <Nav.Link as="a" eventKey="rosterReports" href="javascript:void(0)" id="rosterReports" data-toggle="tab" role="tab" aria-controls="rosterReports" aria-selected="true">
                    Roster Reports
                  </Nav.Link>
                </Nav.Item>
              }
            </Nav>

            <Tab.Content id="rrTabContent-alt">
              <Tab.Pane eventKey="roster">
                <MemberList {...this.props} />
              </Tab.Pane>

              {rosterReport && rosterReport.length > 0 &&
                <Tab.Pane eventKey="rosterReports">
                  <RosterReports selectedCommitteeWithPermission={selectedCommitteeWithPermission} rosterReport={rosterReport} />
                </Tab.Pane>
              }
            </Tab.Content>
          </Tab.Container>
        </MediaQuery>
        <MediaQuery maxDeviceWidth={maxWidthValue.mobile}>
          <MemberList {...this.props} />
        </MediaQuery>
      </>
    );
  }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch => ({
  setClassFilter: classFilter => dispatch(setClassFilter(classFilter)),
  setVoteFilter: voteFilter => dispatch(setVoteFilter(voteFilter)),
  setBasicSearch: voteFilter => dispatch(setBasicSearch(voteFilter))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RosterAndReportsTabComponent);
