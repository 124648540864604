import { clearSession, removeAppCookies } from '../../helpers/utilCommon';
import types from './types';
import { buildURL, callAPI, Urls } from "../../helpers/apiUrls";
import { setAppLoader } from '../app/actions';
import { logoutHandler } from '../../helpers/OktaUtility';

export const setUserInfo = userObj => ({
    type: types.SET_USER_INFO,
    payload: userObj
})

export const setUserPermission = userPermissionObj => ({
    type: types.SET_USER_PERMISSION,
    payload: userPermissionObj
})

export const clearRedis = (cb) => {
    const url = `${buildURL(Urls.clearRedis, null, null)}`;
    callAPI(url, 'get', null, false, true, () => {
        cb(true)
    });
}

export const signOut = (dispatch = null) => {
    dispatch(setAppLoader(true))
    clearRedis(() => {
        clearSession();
        removeAppCookies(false);
        logoutHandler();
    });

    return {
        type: types.SIGN_OUT
    }

}
