import React, { Component } from 'react';
import { connect } from "react-redux";
import { Row, Col, Accordion, OverlayTrigger, Popover, Form, Tooltip as TooltipBootstrap } from 'react-bootstrap';
import { isEmpty, map, find, findIndex, clone, filter, isEqual, orderBy } from 'lodash';
import TableHeader from '../atoms/TableHeader';
import SortByMobile from '../atoms/SortByMobile';
import RenderSelect from '../atoms/Select';
import CustomToggle from '../atoms/CustomToggle';
import MemberCardPopUp from './MemberCardPopUp';
import ConfirmationPopUp from './ConfirmationPopUp';
import { focusOnPopupAndTabNavigationObject, getFormatDate, memberNameFormat } from '../../helpers/utilCommon';
import { classificationUpdate, memberOfficialVoteUpdate, fetchComitteeMemberDetail } from '../../services/rosterService';
import { setPendingSortOrder, setWaitingSortOrder } from '../../redux/filterValues/actions';

import {
    classUnClassifiedName,
    voteNoPendingCode,
    voteNoChangedEmploymentCode,
    voteNoPendingId,
    voteNoChangedEmploymentId,
    maxWidthValue,
    minWidthValue,
    officialVoteOptionsAsPerClassifications
} from '../../helpers/constant';

// reponsive
import MediaQuery from "react-responsive";

const componentOriginName = 'pending-and-wait-member-list';

export class PendingAndWaitMemberList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filteredMembers: props.filteredMembers,
            pendingOrderObj: null,
            waitingOrderObj: null,
            sortedFilteredMembers: props.filteredMembers,
            showPopup: {
                status: false,
                memberid: null,
                committeeid: null,
                leftPosition: '0px',
                topPosition: '0px'
            },
            confirmationPopup: {
                confirmationType: '',
                memberid: null,
                isLoading: false,
                showPopup: false,
                confirmationMessage: '',
                data: null,
                topPosition: '0px',
                leftPosition: '0px'
            },
            selectedAccordianKey: null,
            activeDropDownRef: null
        };
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const { filteredMembers, tabName, pendingOrderObj, waitingOrderObj } = nextProps;
        let sortedFilteredMembers = filteredMembers;
        if (!(isEqual(filteredMembers, prevState.filteredMembers) &&
            isEqual(pendingOrderObj, prevState.pendingOrderObj) &&
            isEqual(waitingOrderObj, prevState.waitingOrderObj))) {
            if (tabName === 'waitlist' && waitingOrderObj) {
                if (waitingOrderObj.order_By === 1) {
                    sortedFilteredMembers = orderBy(filteredMembers, ['organization'], ['asc']);
                } else if (waitingOrderObj.order_By === 2) {
                    sortedFilteredMembers = orderBy(filteredMembers, ['organization'], ['desc']);
                }
            } else if (tabName === 'pending' && pendingOrderObj) {
                if (pendingOrderObj.order_By === 1) {
                    sortedFilteredMembers = orderBy(filteredMembers, ['organization'], ['asc']);
                } else if (pendingOrderObj.order_By === 2) {
                    sortedFilteredMembers = orderBy(filteredMembers, ['organization'], ['desc']);
                }
            }
            return {
                filteredMembers,
                pendingOrderObj,
                waitingOrderObj,
                sortedFilteredMembers
            };
        }
        return null;
    }

    handleClickHeader = (itemName, order_By, isThirdClick) => {
        let { tabName } = this.props;
        let orderObj = {
            order_By,
            itemName,
            isThirdClick
        };
        if (isThirdClick) {
            orderObj = null;
        }
        if (tabName === 'waitlist') {
            this.props.setWaitingSortOrder(orderObj);
        } else {
            this.props.setPendingSortOrder(orderObj);
        }
    }

    handleAccordianKey = (key) => {
        this.setState({ selectedAccordianKey: key });
    }


    focusOnOpenPopOverHandler = (e, _id) => {
        focusOnPopupAndTabNavigationObject.focusOnOpenHandler('.popover-body select');
        this.setState({
            activeDropDownRef: `#${_id}`
        });
    }

    escapeKeyPressPopupOverHandler = (e) => {
        if (e && e.keyCode === 27) {
            const isDropDownListVisible = document.querySelector('.popover-body select');
            if (isDropDownListVisible) {
                focusOnPopupAndTabNavigationObject.focusOnCloseHandler(this.state.activeDropDownRef);
            }
        }
    }

    mountKeyHandlerEvent = () => {
        window.addEventListener('keydown', (e) => {
            this.escapeKeyPressPopupOverHandler(e);
            focusOnPopupAndTabNavigationObject.focusOnKeyPressHandler(e, '.popover-body', 'select')
        });
    }

    unmountKeyHandlerEvent = () => {
        window.removeEventListener('keydown', (e) => {
            focusOnPopupAndTabNavigationObject.focusOnKeyPressHandler(e, '.popover-body', 'select')
        });
    }

    componentDidMount() {
        this.mountKeyHandlerEvent();
    }

    componentWillUnmount() {
        this.unmountKeyHandlerEvent();
    }

    renderMemberInfo = () => {
        const {
            sortedFilteredMembers,
            confirmationPopup,
            selectedAccordianKey,
            showPopup
        } = this.state;

        const {
            selectedCommitteeWithPermission,
            committeeClassificationList,
            voteList,
        } = this.props;

        let classificationOptions = map(committeeClassificationList, (classObj) => ({
            key: classObj.committeeclassificationid + classObj.name,
            value: classObj.committeeclassificationid,
            text: classObj.name
        }));
        return map(sortedFilteredMembers, (member) => {
            const { classsificationName, classsificationColor, voteCode } = member;
            const { iscommitteeclassified } = selectedCommitteeWithPermission;
            const nameFormat = memberNameFormat(member.firstname, member.middlename, member.lastname, 2);
            const joinDate = getFormatDate(member.joindate);

            let isEditClassification = -1;
            let isEditVote = -1;
            if (selectedCommitteeWithPermission && selectedCommitteeWithPermission.iscommitteeclassified !== -1) {
                isEditClassification = selectedCommitteeWithPermission.updateClassification ? 1 : 0;
                isEditVote = selectedCommitteeWithPermission.updateVoting ? 1 : 0;
            }

            let showThreeDots = isEditClassification === 1 || isEditVote === 1 ? true : false;

            let editClassSelectBoxColor = "c8c8c8";
            let editVoteSelectBoxColor = "c8c8c8";
            if (showThreeDots) {
                if (classsificationName && voteCode && classsificationColor) {
                    if (classsificationName !== classUnClassifiedName) {
                        editClassSelectBoxColor = classsificationColor.substr(1);
                        if (!(voteCode === voteNoPendingCode || voteCode === voteNoChangedEmploymentCode)) {
                            editVoteSelectBoxColor = classsificationColor.substr(1);
                        }
                    }
                }
            }
            const editClassSelectBoxClassName = "custom-select-" + editClassSelectBoxColor;
            const editVoteSelectBoxClassName = "custom-select-" + editVoteSelectBoxColor;

            const comitteeType = iscommitteeclassified ? 'classified' : 'unclassified';
            let allowedVoteOptions = [];
            for (let i = 0; i < officialVoteOptionsAsPerClassifications.length; i++) {
                if (officialVoteOptionsAsPerClassifications[i].classificationId === classsificationName) {
                    allowedVoteOptions = officialVoteOptionsAsPerClassifications[i][comitteeType];
                    break;
                }
            }
            let filteredVoteList = filter(voteList, (voteObj) => {
                if (allowedVoteOptions.indexOf(voteObj.code) !== -1) {
                    return true;
                }
                return false;
            });
            let officialVoteOptions = map(filteredVoteList, (voteObj) => ({
                key: voteObj.voteid + voteObj.description,
                value: voteObj.voteid,
                text: voteObj.description
            }));
            return (
                <React.Fragment key={member.memberid}>
                    <MediaQuery minWidth={minWidthValue.tabletOrDesktop}>
                        <tr data-testid="MemberInfoCmp" key={member.memberid}>
                            <td id={"idptd_" + member.memberid} className="name" width="45%">
                                <p className="astm-type-body mb0">
                                    <OverlayTrigger placement="top" overlay={<TooltipBootstrap className={showPopup.status ? 'invisible' : ''} id={"id_" + member.memberid}>{nameFormat}</TooltipBootstrap>}>
                                        <a
                                            data-testid="handlePopupView"
                                            id={"idp_" + member.memberid}
                                            className="astm-type-body astm-link mb0 ellip"
                                            href="javascript:void(0)"
                                            onClick={() => {
                                                this.handlePopupView(true, member.memberid, member.committeeid, member.memberid);
                                            }}
                                        >{nameFormat}</a>
                                    </OverlayTrigger>
                                </p>
                                <p className="astm-type-body joinDate mb0">{joinDate}</p>
                            </td>
                            <td id={"orgptd_" + member.memberid} className="organization" width="40%">
                                {this.renderOrganization(member.organization, member.memberid)}
                            </td>
                            <td className="summaryAction">
                                <div>
                                    {!showThreeDots || (confirmationPopup.showPopup && confirmationPopup.memberid === member.memberid) ? null : (
                                        <OverlayTrigger rootClose={true} key={'bottom'} placement={'bottom'} trigger="click" overlay={
                                            <Popover id={`popover-positioned-bottom`}>
                                                <Popover.Content>
                                                    <div className="application-option-menu">
                                                        <Form>
                                                            {isEditClassification === 0 ? null : (
                                                                <>
                                                                    <label className="label" htmlFor={"classification_" + member.memberid}>Classification</label>
                                                                    {this.renderEditClassification(isEditClassification, classificationOptions, editClassSelectBoxClassName, member)}
                                                                </>
                                                            )}
                                                            {isEditVote === 0 ? null : (
                                                                <>
                                                                    <label className="label mt10" htmlFor={"vote_" + member.memberid}>Official Vote</label>
                                                                    {this.renderEditVote(isEditVote, officialVoteOptions, editVoteSelectBoxClassName, member)}
                                                                </>
                                                            )}
                                                        </Form>
                                                    </div>
                                                </Popover.Content>
                                            </Popover>
                                        }>
                                            <a data-testid="focusOnOpenPopOverHandler" href="javascript:void(0)" onClick={(e) => this.focusOnOpenPopOverHandler(e, `focus_${member.memberid}`)} aria-label="action" style={{ color: '#232f3a' }} id={`focus_${member.memberid}`}><i
                                                className="fas fa-ellipsis-v astm-icon"
                                            ></i></a>
                                        </OverlayTrigger>
                                    )}
                                </div>
                            </td>
                        </tr>
                    </MediaQuery>
                    <MediaQuery maxDeviceWidth={maxWidthValue.mobile}>
                        <tr>
                            <td className="astm-table-td astm-table-td--mobile">
                                <div className="astm-table-td--mobile-content">
                                    <div>
                                        <span className="table-header astm-type-body bold">Name: </span>
                                        <span className="table-data astm-type-body">
                                            <a className="astm-type-body astm-link" href='#' onClick={() => { this.handleMobilePopupView(true, member.memberid, member.committeeid, member.memberid); }}>{nameFormat}</a>
                                        </span>
                                    </div>
                                    <div>
                                        <span className="table-header astm-type-body-bold bold">Join Date: </span>
                                        <span className="table-data astm-type-body">{joinDate}</span>
                                    </div>
                                    <div>
                                        <span className="table-header astm-type-body-bold bold">Organization: </span>
                                        <span className="table-data astm-type-body">{member.organization}</span>
                                    </div>
                                    <CustomToggle
                                        selectedAccordianKey={selectedAccordianKey}
                                        setAccordianKey={this.handleAccordianKey}
                                        eventKey={'key_' + member.memberid}
                                        as='h2'
                                        className='mb-0 inlineBlock'
                                        collapseChild={(
                                            <button className="btn astm-btn btn-link padding0" type="button">
                                                <span>Show More </span>
                                                <i className="fas astm-icon fa-chevron-down"></i>
                                            </button>
                                        )}
                                        expendChild={(
                                            <button className="btn astm-btn btn-link padding0" type="button">
                                                <span>Show Less </span>
                                                <i className="fas astm-icon fa-chevron-up"></i>
                                            </button>
                                        )}
                                    />
                                    <Accordion.Collapse eventKey={'key_' + member.memberid}>
                                        <>
                                            <div className="heading-row">
                                                <div className="heading-label astm-type-body">Classification</div>
                                                <div className="heading-content astm-type-body">
                                                    {this.renderEditClassification(isEditClassification, classificationOptions, editClassSelectBoxClassName, member)}
                                                </div>
                                            </div>
                                            <div className="heading-row">
                                                <div className="heading-label astm-type-body">Official Vote</div>
                                                <div className="heading-content astm-type-body">
                                                    {this.renderEditVote(isEditVote, officialVoteOptions, editVoteSelectBoxClassName, member)}
                                                </div>
                                            </div>
                                        </>
                                    </Accordion.Collapse>
                                </div>
                            </td>
                        </tr>
                    </MediaQuery>
                </React.Fragment >
            );
        })
    }

    renderOrganization = (organization, memberid) => {
        if (isEmpty(organization)) {
            return null;
        }
        const tdSelector = document.getElementById("orgptd_" + memberid);
        if (!isEmpty(tdSelector)) {
            const textMaxHeight = 45;

            const dummyText = document.createElement("div");
            dummyText.innerHTML = organization;
            dummyText.setAttribute("id", "dummyp_org_txt_" + memberid);
            tdSelector.appendChild(dummyText);

            const dummyTextSelector = document.getElementById("dummyp_org_txt_" + memberid);

            let isTruncated = true;
            if (dummyTextSelector.offsetHeight <= textMaxHeight) {
                isTruncated = false;
            }
            dummyTextSelector.parentNode && dummyTextSelector.parentNode.removeChild(dummyTextSelector);

            if (isTruncated) {
                return (
                    <OverlayTrigger key={'top'} placement={'top'} overlay={<TooltipBootstrap id={`tooltip-top`}>{organization}</TooltipBootstrap>} >
                        <p className="astm-type-body block-ellipsis">{organization}</p>
                    </OverlayTrigger>
                );
            }
            return (
                <p className="astm-type-body block-ellipsis">{organization}</p>
            );
        }
    }

    renderEditClassification = (isEditClassification, classificationOptions, editClassSelectBoxClassName, member) => {
        if (isEditClassification === -1) return null;
        const { memberid, committeeclassificationid, classsificationName } = member;
        const { confirmationPopup } = this.state;
        if (isEditClassification) {
            if (isEmpty(classificationOptions)) return null;
            const { loaders } = this.props;
            let updatingField = !isEmpty(loaders[memberid]) && loaders[memberid].isShow ? loaders[memberid] : null;
            let validatingField = (confirmationPopup.confirmationType === 'classification' && confirmationPopup.isLoading && confirmationPopup.memberid === memberid) ? true : false;
            return (
                <RenderSelect
                    data-testid="EditClassification"
                    size="sm"
                    name={"classification_" + memberid}
                    id={"classification_" + memberid}
                    className={"dropdown-loader " + editClassSelectBoxClassName}
                    options={classificationOptions}
                    onChange={(value) => { this.validateClassificationUpdate(value, memberid); }}
                    value={updatingField && updatingField.inputType === 'class' ? updatingField.value : committeeclassificationid}
                    loading={Boolean(updatingField) || validatingField}
                    disabled={Boolean(updatingField)}
                />
            );
        }
        return (
            <span>{classsificationName ? classsificationName : "Not Selected"}</span>
        );
    }

    renderEditVote = (isEditVote, officialVoteOptions, editVoteSelectBoxClassName, member) => {
        if (isEditVote === -1) return null;
        const { memberid, classsificationName, voteid, voteDescription } = member;
        const { selectedCommitteeWithPermission } = this.props;
        const { iscommitteeclassified } = selectedCommitteeWithPermission;
        if (isEditVote) {
            if (iscommitteeclassified && classsificationName === classUnClassifiedName) {
                let votesOptions = [];
                if (voteDescription) {
                    votesOptions.push({ 'key': voteid, 'value': voteid, 'text': voteDescription });
                }
                return (
                    <OverlayTrigger key={'top'} placement={'top'} overlay={<TooltipBootstrap id={`tooltip-top1`}>{'You must select a classification before assigning a vote'}</TooltipBootstrap>} >
                        <div>
                            <RenderSelect
                                size="sm"
                                id={"vote_" + memberid}
                                name={"vote_" + memberid}
                                disabled
                                options={votesOptions}
                                value={voteid} />
                        </div>
                    </OverlayTrigger>
                );
            } 
                const { loaders } = this.props;
                let updatingField = !isEmpty(loaders[memberid]) && loaders[memberid].isShow ? loaders[memberid] : null;
                let officialVoteOptionsWithDefaultValue = clone(officialVoteOptions);
                let checkInOptions = find(officialVoteOptions, { value: voteid });
                if (isEmpty(checkInOptions)) {
                    officialVoteOptionsWithDefaultValue.unshift({ 'key': voteid, 'value': voteid, 'text': voteDescription });
                }
                return (
                    <RenderSelect
                        size="sm"
                        name={"vote_" + memberid}
                        id={"vote_" + memberid}
                        className={"dropdown-loader " + editVoteSelectBoxClassName}
                        options={officialVoteOptionsWithDefaultValue}
                        onChange={(value) => { this.onChangeVoteHandler(value, memberid); }}
                        value={updatingField && updatingField.inputType === 'vote' ? updatingField.value : voteid}
                        loading={Boolean(updatingField)}
                        disabled={Boolean(updatingField)}
                    />
                );
            
        }
        return (
            <span>{voteDescription ? voteDescription : "Not Selected"}</span>
        );
    }

    onChangeClassHandler = (data) => {
        const { committeeClassificationId, memberid } = data;
        let { memberList } = this.props;
        let updatedMemberIndex = findIndex(memberList.committeemembers, { memberid });
        memberList.committeemembers[updatedMemberIndex].committeeclassificationid = committeeClassificationId;

        let classObj = {
            memberid,
            committeeid: this.props.selectedCommittee,
            committeeClassificationId,
            memberList
        };

        this.props.classificationUpdate(classObj);
    }

    checkIsMainComittee() {
        const { selectedCommittee, committeeList } = this.props;
        let selectedComitteeDesignation = false;
        for (let i = 0; i < committeeList.length; i++) {
            if (committeeList[i].committeeid === selectedCommittee) {
                selectedComitteeDesignation = committeeList[i].code;
                break;
            }
        }
        let isMainComittee = (selectedComitteeDesignation.indexOf('.') === -1) ? true : false;
        return isMainComittee;
    }

    checkIsProducer(committeeClassificationId) {
        const { committeeClassificationList } = this.props;
        let isClassificationProducer = false;
        for (let i = 0; i < committeeClassificationList.length; i++) {
            if (committeeClassificationList[i].committeeclassificationid === committeeClassificationId) {
                if (committeeClassificationList[i].name.toLowerCase() === 'producer') {
                    isClassificationProducer = true;
                }
                break;
            }
        }
        return isClassificationProducer;
    }

    validateClassificationUpdate = (committeeClassificationId, memberid) => {
        const { selectedCommitteeWithPermission, selectedCommittee, committeeList } = this.props;
        const { iscommitteeclassified, updateClassification } = selectedCommitteeWithPermission;
        if (iscommitteeclassified && updateClassification && this.checkIsMainComittee() && !this.checkIsProducer(committeeClassificationId)) {
            let leftPosition = '10px';
            let topPosition = '0px';
            this.setState({
                confirmationPopup: {
                    confirmationType: 'classification',
                    isLoading: true,
                    showPopup: false,
                    data: null,
                    topPosition,
                    leftPosition,
                    memberid
                }
            }, () => {
                fetchComitteeMemberDetail({ committeeid: selectedCommittee, memberid }, (errMsg, data) => {
                    if (data) {
                        const { othercommitteinfo } = data[0];
                        const subcomitteeClassificationProducer = [];
                        let comitteeIndex = findIndex(committeeList, { committeeid: selectedCommittee });
                        for (let i = 0; i < othercommitteinfo.length; i++) {
                            let { classification, committeedesignation } = othercommitteinfo[i];
                            if (committeedesignation.indexOf(committeeList[comitteeIndex].code) !== -1 && classification === 'Producer') {
                                subcomitteeClassificationProducer.push(othercommitteinfo[i].committeedesignation);
                            }
                        }
                        if (subcomitteeClassificationProducer.length > 0) {
                            const confirmationMessage = this.prepareConfirmationMessageForClassificationUpdate(subcomitteeClassificationProducer);
                            this.setState({
                                confirmationPopup: {
                                    confirmationType: 'classification',
                                    isLoading: false,
                                    showPopup: true,
                                    confirmationMessage: confirmationMessage,
                                    data: { committeeClassificationId, memberid },
                                    topPosition,
                                    leftPosition,
                                    memberid
                                }
                            });
                        } else {
                            this.setState({
                                confirmationPopup: {
                                    confirmationType: '',
                                    isLoading: false,
                                    showPopup: false,
                                    confirmationMessage: '',
                                    data: null,
                                    topPosition: '0px',
                                    leftPosition: '0px',
                                    memberid: null
                                }
                            });
                            this.onChangeClassHandler({ committeeClassificationId, memberid });
                        }
                    } else {
                        this.setState({
                            confirmationPopup: {
                                confirmationType: '',
                                isLoading: false,
                                showPopup: false,
                                confirmationMessage: '',
                                data: null,
                                topPosition: '0px',
                                leftPosition: '0px',
                                memberid: null
                            }
                        });
                    }
                });
            });
        } else {
            this.onChangeClassHandler({ committeeClassificationId, memberid });
        }
    }

    prepareConfirmationMessageForClassificationUpdate(subcomittees) {
        const confirmationMessage = 'According to ASTM Regulations Section 7 on Classification of Committee members, a member classified on any subcommittee as a producer shall be classified on the Main Committee as a producer. The member is already a producer under ' + subcomittees.join(' , ') + '. Do you want to continue updating the classification?';
        return confirmationMessage;
    }

    onChangeVoteHandler = (voteid, memberid) => {
        let { memberList } = this.props;
        const updatedMemberIndex = findIndex(memberList.committeemembers, { memberid });
        const oldVoteId = memberList.committeemembers[updatedMemberIndex].voteid;
        memberList.committeemembers[updatedMemberIndex].voteid = voteid;

        let updatedTotalApplication = this.props.updatedPendingApplication;

        if ((oldVoteId === voteNoPendingId || oldVoteId === voteNoChangedEmploymentId) &&
            voteid !== voteNoPendingId &&
            voteid !== voteNoChangedEmploymentId) {
            updatedTotalApplication -= 1;
        } else if (oldVoteId !== voteNoPendingId &&
            oldVoteId !== voteNoChangedEmploymentId &&
            (voteid === voteNoPendingId || voteid === voteNoChangedEmploymentId)) {
            updatedTotalApplication += 1;
        }

        let voteObj = {
            memberid,
            committeeid: this.props.selectedCommittee,
            voteid,
            memberList,
            updatedTotalApplication
        };

        this.props.memberOfficialVoteUpdate(voteObj);
    }

    handleContentUpdate = (updatedCommitteeid, updatedMemberid, origin) => {
        const { committeeid, memberid } = this.state.showPopup;
        if (committeeid !== updatedCommitteeid || memberid !== updatedMemberid || origin !== componentOriginName) {
            this.handlePopupView(false, null, null, null);
        }
    }

    handlePopupView = (value, memberid, committeeid, elementId) => {
        let leftPosition = '0px';
        let topPosition = '0px';
        if (elementId) {
            let spanId = "#idp_" + elementId;
            let spanSelector = document.querySelector(spanId);
            let spanBoxView = spanSelector.getBoundingClientRect();
            leftPosition = (spanBoxView.x + spanBoxView.width + 20) + 'px';
            topPosition = (spanBoxView.y - 17) + 'px';
        }
        this.setState({
            showPopup: {
                status: value,
                memberid,
                committeeid,
                leftPosition,
                topPosition
            }
        }, () => {
            if (value) {
                if (document.querySelector('#root'))
                    document.querySelector('#root').classList.add('overflow-hide');
                document.querySelector('#pendingTableBody').classList.add('overflow-hide');
            } else {
                document.querySelector('#root').classList.remove('overflow-hide');
                document.querySelector('#pendingTableBody').classList.remove('overflow-hide');
            }
        });
    }

    handleMobileContentUpdate = (updatedCommitteeid, updatedMemberid) => {
        const { committeeid, memberid } = this.state.showPopup;
        if (committeeid !== updatedCommitteeid || memberid !== updatedMemberid) {
            this.handleMobilePopupView(false, null, null);
        }
    }

    handleMobilePopupView = (value, memberid, committeeid) => {
        let leftPosition = '0px';
        let topPosition = '0px';
        this.setState({
            showPopup: {
                status: value,
                memberid,
                committeeid,
                leftPosition,
                topPosition
            }
        });
    }

    handleConfirm = (data, confirmationType) => {
        this.setState({
            confirmationPopup: {
                confirmationType: '',
                isLoading: false,
                showPopup: false,
                confirmationMessage: '',
                data: null,
                topPosition: '0px',
                leftPosition: '0px',
                memberid: null
            }
        });
        if (confirmationType === "classification") {
            this.onChangeClassHandler(data);
        }
    }

    handleClosePopup = () => {
        this.setState({
            confirmationPopup: {
                confirmationType: '',
                isLoading: false,
                showPopup: false,
                confirmationMessage: '',
                data: null,
                topPosition: '0px',
                leftPosition: '0px',
                memberid: null
            }
        });
    }

    render() {
        const {
            showPopup,
            sortedFilteredMembers,
            pendingOrderObj,
            waitingOrderObj,
            confirmationPopup
        } = this.state;

        const {
            selectedCommitteeWithPermission,
            tabName
        } = this.props;

        let noRecordMessage = null;
        let order = 0;

        if (tabName === 'waitlist') {
            noRecordMessage = "No Member(s) in Producer Wait List.";
            if (waitingOrderObj) {
                order = waitingOrderObj.order_By;
            }
        } else {
            noRecordMessage = "No Pending Applications to display.";
            if (pendingOrderObj) {
                order = pendingOrderObj.order_By;
            }
        }
        let headerProps = [
            { 'title': 'Name', 'hasSorting': false, 'className': "", 'sortKey': 'm_name', },
            { 'title': 'Organization', 'hasSorting': true, 'className': "", 'sortKey': 'organization', 'orderBy': order, 'thirdClickOff': true },
        ];
        return (
            <>
                <MediaQuery minWidth={minWidthValue.tabletOrDesktop}>
                    <Form data-testid="PendingMemberListComponent">
                        <Row>
                            <Col xs={12} md={12}>
                                <div className="table astm-table">
                                    {!isEmpty(sortedFilteredMembers) ? (
                                        <table className="table astm-table roasterTable scrollableTable">
                                            <thead>
                                                <tr>
                                                    <TableHeader
                                                        headerProps={headerProps}
                                                        onClickHeader={this.handleClickHeader}
                                                    ></TableHeader>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody id="pendingTableBody">
                                                {this.renderMemberInfo()}
                                            </tbody>
                                        </table>
                                    ) : (
                                        <table className="table astm-table">
                                            <tbody>
                                                <tr><td className="txtCenter">{noRecordMessage}</td></tr>
                                            </tbody>
                                        </table>
                                    )}
                                </div>
                                {showPopup.status ? (
                                    <MemberCardPopUp
                                        memberId={showPopup.memberid}
                                        committeeId={showPopup.committeeid}
                                        selectedCommitteeWithPermission={selectedCommitteeWithPermission}
                                        leftPosition={showPopup.leftPosition}
                                        topPosition={showPopup.topPosition}
                                        isPendingPopup={true}
                                        onClose={this.handlePopupView}
                                        contentUpdated={this.handleContentUpdate}
                                        origin={componentOriginName}
                                    />
                                ) : null}
                                {confirmationPopup.showPopup ? (
                                    <ConfirmationPopUp
                                        leftPosition={confirmationPopup.leftPosition}
                                        topPosition={confirmationPopup.topPosition}
                                        confirmationMessage={confirmationPopup.confirmationMessage}
                                        confirmationType={confirmationPopup.confirmationType}
                                        data={confirmationPopup.data}
                                        handleConfirm={this.handleConfirm}
                                        handleClosePopup={this.handleClosePopup}
                                    />
                                ) : null}
                            </Col>
                        </Row>
                    </Form>
                </MediaQuery>
                <MediaQuery maxDeviceWidth={maxWidthValue.mobile}>
                    {!isEmpty(sortedFilteredMembers) ? (
                        <>
                            <div className="accordion-sort clearfix">
                                <SortByMobile label={'Sort by'} headerProps={headerProps} onClickHeader={this.handleClickHeader} />
                            </div>
                            <Accordion>
                                <table className="table astm-table table-striped roasterTable">
                                    <tbody>
                                        {this.renderMemberInfo()}
                                    </tbody>
                                </table>
                            </Accordion>
                        </>
                    ) : (
                        <table className="table astm-table">
                            <tbody>
                                <tr><td className="txtCenter">{noRecordMessage}</td></tr>
                            </tbody>
                        </table>
                    )}
                    {showPopup.status ? (
                        <MemberCardPopUp
                            memberId={showPopup.memberid}
                            committeeId={showPopup.committeeid}
                            selectedCommitteeWithPermission={selectedCommitteeWithPermission}
                            leftPosition={showPopup.leftPosition}
                            topPosition={showPopup.topPosition}
                            isPendingPopup={true}
                            onClose={this.handleMobilePopupView}
                            contentUpdated={this.handleMobileContentUpdate}
                        />
                    ) : null}
                    {confirmationPopup.showPopup ? (
                        <ConfirmationPopUp
                            leftPosition={confirmationPopup.leftPosition}
                            topPosition={confirmationPopup.topPosition}
                            confirmationMessage={confirmationPopup.confirmationMessage}
                            confirmationType={confirmationPopup.confirmationType}
                            data={confirmationPopup.data}
                            handleConfirm={this.handleConfirm}
                            handleClosePopup={this.handleClosePopup}
                        />
                    ) : null}
                </MediaQuery>
            </>
        );
    }
}

const mapStateToProps = state => {
    let { loaders, roster, filters } = state;
    return {
        selectedCommittee: roster.selectedCommittee,
        updatedPendingApplication: roster.updatedPendingApplication,
        loaders,
        pendingOrderObj: filters.pendingOrderObj,
        waitingOrderObj: filters.waitingOrderObj,
        committeeList: roster.committeeList
    }
};

const mapDispatchToProps = dispatch => ({
    classificationUpdate: value => dispatch(classificationUpdate(value)),
    memberOfficialVoteUpdate: value => dispatch(memberOfficialVoteUpdate(value)),
    setPendingSortOrder: value => dispatch(setPendingSortOrder(value)),
    setWaitingSortOrder: value => dispatch(setWaitingSortOrder(value))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PendingAndWaitMemberList);