/* eslint-disable no-empty-function */
import React, { Component } from 'react';
import { Redirect, Switch, withRouter, Route } from 'react-router-dom';
import { ProtectedRosterRoute } from '../../../hoc/RouteHOC';
import CustomLoader from '../../atoms/CustomLoader';
import ToastMessage from '../../atoms/ToastMessage';
import FooterComp from '../../organisms/Footer';
import HeaderComp from '../../organisms/Header';
import Roster from '../roster';
import CacheBuster from '../../../helpers/CacheBuster';
import { withOktaAuth, SecureRoute } from '@okta/okta-react';
import { clearSession, getUserInfo } from '../../../helpers/utilCommon';
import { LOGIN_USER_MODEL } from '../../../models/common.models';
import { get } from 'lodash';


class App extends Component {
  componentDidMount = () => {
    this.props.checkUser();
    this.props.verifyMECallForSession();
    this.verifyUserInfoHandler();
    if (this.props.isLoadOKTAConfigurationTest) {
      this.loadOKTAConfiguration();
    }
  }
  
  loadOKTAConfiguration = () => {
    this.props.OIDC.tokenManager.get('idToken').then(tokenInfo => {
      const useInfo = getUserInfo();
      if (tokenInfo && !useInfo) {
        const tokenClaims = get(tokenInfo, 'claims');
        let data = {};
        data.userInfo = tokenClaims;
        data['request_mode'] = LOGIN_USER_MODEL.MEMBER_LOGIN;
        this.props.oktaLogin(data, () => {
          this.props.checkUser();
        });
      }
    });
  }

  verifyUserInfoHandler() {
    // TODO : Verify user information has been update while refresh page.
    const userInfo = getUserInfo();
    const { getAuthHeaderToken } = this.props;
    if (userInfo && getAuthHeaderToken()) {
      let data = {};
      data['request_mode'] = LOGIN_USER_MODEL.VERIFY_INFO;
      data.userInfo = userInfo;
      this.props.oktaLogin(data, () => { });
    }
  }
  // TODO : componentWillReceiveProps hooks to check app isAuthenticated.
  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps(nextProps) {
    const { authState } = this.props;
    if (authState && nextProps.authState && authState.isAuthenticated !== nextProps.authState.isAuthenticated) {
      this.loadOKTAConfiguration();
    }
  }

  render() {
    const { showToastMessage, isMiniAppLoading, content, uuidNumber, userInfo, userPermission, isAppLoading, authState: { isPending }, isReactTesting } = this.props;
    const Router = isReactTesting ? Route : SecureRoute;
    return (
      <>
        {isAppLoading || isPending ? < CustomLoader testId='app-spinner' isVisible={true} /> : null}
        {isMiniAppLoading ? <CustomLoader testId='mini-spinner' isVisible={true} /> : null}
        {showToastMessage && (<div className="topAlert"><ToastMessage content={content} uuidNumber={ uuidNumber} /></div>)}
        <Switch>
          {userInfo ? <Redirect exact from="/" to="/app" /> : null}
          {!userInfo ? <Redirect exact from="/app" to="/" /> : null}
          <ProtectedRosterRoute exact path='/app' userPermission={userPermission} {...this.props} >
            <HeaderComp {...this.props} />
            <Roster />
            <FooterComp />
          </ProtectedRosterRoute>
          <Router exact path="/" />
          <Redirect from={'/login'} to="/app" />
          <Route path='*' component={(props) => {
            const { pathname } = props.location;
            if (pathname !== '/auth') {
              window.open(process.env.REACT_APP_PUB_404_URL, "_self");
            }
            return false;
          }} />
        </Switch>

        <CacheBuster>
          {({ loading, isLatestVersion, refreshCacheAndReload }) => {
            if (loading) return null;
            if (!loading && !isLatestVersion) {
              refreshCacheAndReload();
            }
            return (
              <div>
              </div>
            );

          }}
        </CacheBuster>
      </>

    );
  }
}

export default withOktaAuth(withRouter(App));