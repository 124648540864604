import axios from 'axios';
import { get } from 'lodash';
import { signOut, setUserInfo, setUserPermission } from '../redux/login/actions';
import store from '../store';
import { getAuthHeaderToken } from './OktaUtility';
import { setMessage } from '../redux/shared/actions';
import { generateUUID,cacheManager } from './utilCommon';
import { IDENTITY_DELEGATION_FLAG, USER_INFO } from '../models/common.models';

axios.interceptors.request.use(async (config) => {
    let authToken = await getAuthHeaderToken();
    // TODO : "s-origin" use for time being.
    config.headers['s-origin'] = window.location.origin;
    let delegation = cacheManager.getItem(USER_INFO) ? get(JSON.parse(cacheManager.getItem(USER_INFO)), IDENTITY_DELEGATION_FLAG) : null;
    let IdentityDelegationUserId = window.btoa(delegation?.proxymemberuserid);
    if (authToken) {
        config.headers['Authorization'] = `Bearer ${authToken}`;
    }
    if (delegation?.proxymemberuserid) {
        config.headers['IdentityDelegationUserId'] = IdentityDelegationUserId;
    }
    if(window.localStorage.getItem('storeUUIDForAllApiCall') === "" || window.localStorage.getItem('storeUUIDForAllApiCall') === null){
        window.localStorage.setItem('storeUUIDForAllApiCall', JSON.stringify({}));
    }
    let generateUUIDForApiCall = generateUUID(true);
    let storeUUIDArray = JSON.parse(window.localStorage.getItem('storeUUIDForAllApiCall'));
    let newStoreUUIDArray = {...storeUUIDArray};
    newStoreUUIDArray[config.url ] = generateUUIDForApiCall;
    window.localStorage.setItem('storeUUIDForAllApiCall', JSON.stringify(newStoreUUIDArray));
    config.headers.uniquerequestid = generateUUIDForApiCall;
    return config;
}, (error) => {
    return Promise.reject(error);
})

axios.interceptors.response.use((response) => {
    return response;
}, (error) => {
    if (error.message === 'Network Error') {
        return Promise.reject({ responsestatus: false, responsecode: 503 });
    }
    return false;
});

// TODO : Auto logout on Invalid session
export const autoLogout = () => {
    store.dispatch(setMessage(true, '401'));
    store.dispatch(setUserInfo(null));
    store.dispatch(setUserPermission(null));
    signOut(store.dispatch);
}

export default axios;