import { connect } from "react-redux";
import { filter, isEmpty, map, find, assign, get } from "lodash";
import Roster from "./RosterComponent";
import { fetchCommittee } from "../../../services/rosterService";

import {
  clearRosterSortOrder,
  clearPendingSortOrder,
  clearWaitingSortOrder,
  clearAllFilter,
  resetAdvanceSearch
} from "../../../redux/filterValues/actions";

import { updatePendingApplication } from "../../../redux/roster/actions";
import { commonCommitteeInfoAction } from "../../../services/appServices";

const mapStateToProps = state => {
  const { user, roster } = state;
  const userPermission = user.userPermission;
  const permissions = userPermission.permissions;

  const {
    selectedCommittee,
    committeeSummary,
    isMembersLoading,
    memberList,
    committeeClassificationList,
    voteList,
    rosterReport
  } = roster;

  let selectedCommitteeWithPermission = null;
  if (!isEmpty(committeeSummary)) {
    let view = filter(permissions, {
      committeeid: selectedCommittee,
      responsibilityprivilegeid: 1,
      haspermission: true
    });
    let updateClassification = filter(permissions, {
      committeeid: selectedCommittee,
      responsibilityprivilegeid: 2,
      haspermission: true
    });
    let updateVoting = filter(permissions, {
      committeeid: selectedCommittee,
      responsibilityprivilegeid: 3,
      haspermission: true
    });
    const selectedCommitteeObj = find(roster.committeeList, { committeeid: selectedCommittee })
    selectedCommitteeWithPermission = {
      id: selectedCommittee,
      iscommitteeclassified: committeeSummary.iscommitteeclassified,
      view: isEmpty(view) ? false : true,
      updateClassification: isEmpty(updateClassification) ? false : true,
      updateVoting: isEmpty(updateVoting) ? false : true,
      designation: selectedCommitteeObj.code,
      title: selectedCommitteeObj.title
    };
  }

  let modifiedMemberList = [];
  let totalrecords = 0;
  let modifiedLoading = true;
  if (
    !(
      isMembersLoading ||
      !rosterReport
    )
  ) {
    modifiedLoading = false;
    totalrecords = memberList.totalrecords;
    modifiedMemberList = map(memberList.committeemembers, member => {
      let classsification = find(committeeClassificationList, {
        committeeclassificationid: member.committeeclassificationid
      });
      let vote = find(voteList, { voteid: member.voteid });
      let newMemberObj = assign(member, {
        classsificationName: classsification ? get(classsification, 'name') : null,
        classsificationColor: classsification ? get(classsification, 'colorcode') : null,
        voteCode: get(vote, 'code'),
        voteDescription: get(vote, 'description')
      });
      return newMemberObj;
    });
  }

  let committeeMemberList = {
    committeemembers: modifiedMemberList,
    totalrecords
  };

  return {
    userPermission,
    updatedPendingApplication: roster.updatedPendingApplication,
    isCommitteeListLoading: roster.isCommitteeListLoading,
    committeeList: roster.committeeList,
    selectedCommittee: roster.selectedCommittee,
    selectedCommitteeWithPermission,
    isSummaryLoading: roster.isSummaryLoading,
    committeeSummary: roster.committeeSummary,
    isMembersLoading: modifiedLoading,
    memberList,
    committeeMemberList,
    committeeClassificationList,
    voteList,
    rosterReport
  };
};

const mapDispatchToProps = dispatch => ({
  fetchCommittee: committeeid => dispatch(fetchCommittee(committeeid)),
  updatePendingApplication: updatedApplication => dispatch(updatePendingApplication(updatedApplication)),
  clearRosterSortOrder: committeeid => dispatch(clearRosterSortOrder(committeeid)),
  clearPendingSortOrder: committeeid => dispatch(clearPendingSortOrder(committeeid)),
  clearWaitingSortOrder: committeeid => dispatch(clearWaitingSortOrder(committeeid)),
  clearAllFilter: committeeid => dispatch(clearAllFilter(committeeid)),
  resetAdvanceSearch: () => dispatch(resetAdvanceSearch()),
  commonCommitteeInfo: committeeId => dispatch(commonCommitteeInfoAction(committeeId))
});

const RosterContainer = connect(mapStateToProps, mapDispatchToProps)(Roster);

export default RosterContainer;
